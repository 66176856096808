<template>
  <div class="project-item">
    <div class="project-item-main">
      <img src="../../../assets/images/Project/u438_div.png" width="100%">
      <div class="project-item-main-title">
        <div class="project-item-main-title1">{{ $i18n.locale == 'zh'? selectedData.title2 : selectedData.title }}</div>
        <div class="project-item-main-title2">{{ $i18n.locale == 'zh'? selectedData.title : selectedData.title2 }}</div>
        <div class="project-item-main-title3">
          {{ $i18n.locale == 'zh'? '将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来' : 'Combine the Breed with Smart Sensor, Data Transfer and Intelligent Controll' }}
        </div>
        <a class="mt20" href="#img">
          <img src="../../../assets/images/Project/u439.svg" alt="" >
        </a>
      </div>
    </div>
    <div id="img" class="project-item-main-img">
      <div class="project-item-main-img-title1" style="cursor: pointer">
        <span @click="goPath">{{ $t('lang.Solution') }}</span>>{{ $i18n.locale == 'zh'?selectedData.title : selectedData.title2 }}
      </div>
      <div class="mt20 mb20">
        <img :src="selectedData.img" alt="" width="100%">
      </div>
    </div>
    <div style="width: 100%">
      <bottom/>
    </div>
  </div>

</template>

<script>
import bottom from "@/components/bottom";
export default {
  name: "item1",
  components: {
    bottom
  },
  props: {
    selectedData: {}
  },
  methods: {
    goPath() {
      this.$router.push({ path: '/Project' })
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.project-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-item-main {
  position: relative;
  width: 100%;

  .project-item-main-title {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .project-item-main-title1 {
      font-size: 35px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
      font-weight: 650;
      font-style: normal;
      color: #FFFFFF;
      padding-bottom: 0px;
    }

    .project-item-main-title2 {
      font-size: 60px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
      font-weight: 650;
      font-style: normal;
      color: #00834b;
      padding-bottom: 10px;
      border-bottom: 5px solid #FFFFFF;
    }

    .project-item-main-title3 {
      padding-top: 10px;
      font-size: 30px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
      font-weight: 650;
      font-style: normal;
      color: #FFFFFF;
    }
  }

}

.project-item-main-img {
  width: 60%;

  .project-item-main-img-title1 {
    font-size: 14px;
    font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
    font-weight: 650;
    font-style: normal;
    padding: 20px 0;
    color: #959595;
    border-bottom: 1px solid #959595;
  }
}
</style>
