<template>
  <div class="app-container">
    <div style="width: 100%">
      <item1 :selectedData="selectedData"/>
    </div>
  </div>
</template>

<script>
import item1 from "@/views/Project/component/item1";
export default {
  name: "item",
  components: {
    item1
  },
  mounted() {
    this.selectedData = this.showData[this.$route.query.id]
  },
  data() {
    return {
      selectedData: {},
      showData: [
        {
          title: '智慧养殖数智化解决方案',
          title2: 'DIoT Solution Of Intelligent Breeding',
          info: '简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …',
          info2: 'Brief description: Through big data calculation and artificial intelligence, we constantly optimize breeding parameters, improve breeding technology, promote intelligent breeding, solve the pain points of low breeding efficiency, and provide intelligent breeding solutions for breeding farms',
          img: require('../../assets/images/Project/1.png')
        },
        {
          title: '数智化洗消烘干解决方案',
          title2: 'DIoT Cleaning & Stoving Solution',
          info: '简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …',
          info2: 'Brief description: Through vehicle type recognition, license plate information collection, automatic report to the cloud platform, the drying process is clear,  the information is comprehensively recorded and the problem is traced, provid efficient and energy-saving decontamination and drying solutions for the farms.',
          img: require('../../assets/images/Project/2.png')
        },
        {
          title: 'IB2S智能楼房解决方案',
          title2: 'Intelligent Building Breeding Solution',
          info: '简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …',
          info2: 'Brief description: By adopting the centralized ventilation mode, a complete biological asset safety system is established, data collection, remote detection, automatic analysis and control are realized, and the aquaculture is transparent and visualized.',
          img: require('../../assets/images/Project/3.png')
        },
        {
          title: '集中报警解决方案',
          title2: 'Center Alarm Warn Solution',
          info: '简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …',
          info2: 'Brief description: Through AI camera intelligent information collection, artificial intelligence, big data analysis and other technologies, abnormalities in the factory will be alerted in time, daily and weekly reports will be automatically pushed, and problem location and traceability can be realized',
          img: require('../../assets/images/Project/4.png')
        }
      ],
    }
  }
}
</script>

<style scoped>

</style>
